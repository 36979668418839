<template>
  <div @click="$store.state.seeContact = false" class="overlay"></div>
  <div class="window">
    <div class="content">
      <div v-motion-pop class="left">
        <h2>Kontaktujte nás</h2>
        <p>Tešíme sa na vašu správu a príležitosť spolupracovať s vami.</p>

        <div class="buttons">
          <div class="button">
            <a href="tel:+421917471508"
              >Zavolajte nám

              <img src="@/assets/icons/arrow.svg" alt="Odoslať" />
            </a>
            <p class="smaller">0917 471 508</p>
          </div>
          <div class="button">
            <a href="mailto:lubos@luboskukla.sk"
              >Napíšte nám

              <img src="@/assets/icons/arrow.svg" alt="Odoslať" />
            </a>
            <p class="smaller">lubos@luboskukla.sk</p>
          </div>
        </div>
      </div>
      <div v-motion-slide-right class="right">
        <form method="POST" action="https://luboskukla.sk/php/sendMail.php">
          <div class="input">
            <label for="name"> Celé meno </label>
            <input placeholder="Ľuboš Kukla" type="text" name="name" />
          </div>

          <div class="input">
            <label for="email">Email</label>
            <input
              placeholder="lubos@luboskukla.sk"
              type="email"
              name="email"
            />
          </div>

          <div class="input">
            <label class="label-number" for="number">Tel. číslo</label>
            <div class="tel-number">
              <select name="countryCode" id="">
                <option data-countryCode="SK" value="+421">
                  +421 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Slovensko
                </option>
                <option data-countryCode="CZ" value="+42">
                  +42 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Česká republika
                </option>
                <option data-countryCode="PL" value="+48">
                  +48 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Poľsko
                </option>
              </select>
              <input placeholder="917 471 508" type="tel" name="number" id="" />
            </div>
          </div>

          <div class="input">
            <label for="message">Správa</label>
            <textarea
              placeholder="Napíšte nám..."
              name="message"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>

          <button type="submit" class="button"><a> Odoslať správu </a></button>
        </form>
      </div>
      <img
        @click="$store.state.seeContact = false"
        class="exit"
        src="@/assets/icons/exit.svg"
        alt=""
      />

      <div class="links">
        <a
          v-motion-slide-bottom
          target="_blank"
          href="https://www.instagram.com/luboskukla_webs/?hl=sk"
          ><img
            class="instagram"
            src="@/assets/icons/instagram.svg"
            alt="instagram"
        /></a>
        <a
          v-motion-slide-bottom
          target="_blank"
          href="https://www.facebook.com/KuklaLubos/"
          ><img src="@/assets/icons/facebook.svg" alt="facebook"
        /></a>
        <a v-motion-slide-bottom href="messenger.com" target="_blank"
          ><img src="@/assets/icons/messenger.svg" alt="messenger"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleScroll() {
      // Any code to be executed when the window is scrolled
      this.isUserScrolling = window.scrollY > 800;

      if (window.scrollY > 800) {
        this.$store.state.seeContact = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    (this.handleDebouncedScroll = this.handleScroll), 100;
    window.addEventListener("scroll", this.handleDebouncedScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 300vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
}
.window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 60vw;
  width: auto;
  max-width: 90vw;
  min-height: 60vh;
  height: auto;
  padding: 2rem 5%;

  border-radius: 1.25rem;
  border: 1px solid rgba(253, 253, 253, 0.31);
  background: rgba(29, 26, 36, 0.63);
  backdrop-filter: blur(100px) opacity(0.8);

  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
}

.exit {
  position: absolute;
  right: -7%;
  top: 2%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    transform: scale(1.1) translate(-50%, -50%);
    transition-duration: 0.1s;
  }
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
  padding: 1rem 0;
}
.links a img:hover {
  transform: scale(1.1);
  transition-duration: 0.1s;
}

.links img {
  width: 2rem;
  height: 2rem;
}

.links .instagram {
  width: 1.85rem;
  height: 1.85rem;
}

.links {
  gap: 5%;
}

.links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5%;
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5%;

  min-height: 60vh;
  height: 100%;
  min-width: 60vw;
  width: 100%;
  max-width: 90vw;
}

.left {
  width: 62.5%;
}

.right {
  width: 35%;
}

h2 {
  background: linear-gradient(
    177deg,
    #f9f9f9 2.85%,
    rgba(249, 249, 249, 0.29) 156.1%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: center;
  font-size: 3.75rem;
  font-weight: 700;
  margin: 0;
}

p {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  width: 75%;
  color: $scd-clr;
  margin: 0.5rem auto;
}

.smaller {
  font-size: 1.15rem;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.button a {
  margin: 2rem auto 0.7rem;
}

label {
  color: $scd-clr;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.00438rem;
  margin: 0.1rem;
}

input,
textarea {
  border-radius: 0.75rem;
  border: 1px solid #e0e0e0;
  color: $txt-clr;
  padding: 1rem 1.5rem;
  background: transparent;
  font-weight: 500;
  width: 100%;
  margin-top: 0.4rem;
  box-sizing: border-box;
}

input,
textarea,
select {
  &:focus-visible {
    outline: none;
  }
}

textarea {
  resize: none;
}

form button {
  background: transparent;
  border: none;
  width: 100%;

  a {
    margin-right: 0 !important;
  }
}

.tel-number {
  display: flex;
  width: 100%;
}

select {
  border-radius: 0.75rem;
  border: none;
  color: $txt-clr;
  background: transparent;
  width: 50%;
  max-width: 6rem;
  margin-right: 1rem;
  padding: 0.7rem 0.5rem 0.4rem 1vw;
}

.input:not(:first-child) {
  margin-top: 1.25rem;
}

::placeholder {
  color: rgba(180, 188, 208, 0.31);
  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.005rem;
}

@media screen and (max-width: 1300px) {
  .window {
    min-width: 80vw;
  }

  .exit {
    right: -5%;
  }
}
@media screen and (max-width: 1000px) {
  h2 {
    font-size: 3rem;
  }

  p {
    font-size: 1.1rem;
  }

  .smaller {
    font-size: 1rem;
  }
}
@media screen and (max-width: 900px) {
  .buttons {
    flex-direction: column;
  }

  .left > p {
    margin-bottom: 1rem;
  }

  .right {
    width: 37.5%;
  }

  .left {
    width: 60%;
  }

  .button a {
    margin: 1rem auto 0.7rem;
  }
}
@media screen and (max-width: 800px) {
  .content {
    flex-direction: column;
  }

  body {
    overflow-y: hidden;
  }

  .overlay {
    height: 300%;
  }

  .window {
    padding: 2rem 5% 3.8rem;
    top: 10%;
    transform: translate(-50%, 0);
  }

  .links {
    bottom: -4rem;
  }

  .exit {
    top: 0;
  }

  .left {
    width: 100%;
    max-width: 25rem;
  }

  .right {
    width: 100%;
    max-width: 25rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  p {
    font-size: 1rem;
  }

  .smaller {
    font-size: 0.95em;
  }
}
</style>
