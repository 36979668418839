<template>
  <TheLoader v-if="isLoaded" />

  <TheMenu v-if="!isLoaded" />
  <router-view v-motion-fade :delay="500" v-if="!isLoaded" />
  <TheFooter v-if="!isLoaded" />
</template>

<script>
import TheMenu from "@/components/MainComponents/TheMenu.vue";
import TheLoader from "@/components/Functionality/TheLoader.vue";
import TheFooter from "@/components/MainComponents/TheFooter.vue";

export default {
  components: {
    TheMenu,
    TheLoader,
    TheFooter,
  },
  mounted() {
    window.scrollTo(0, 0);
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = false;
      }
    };
  },
  data() {
    return {
      isLoaded: true,
    };
  },
};
</script>

<style lang="scss">
//import font
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

//import global CSS

@import "@/assets/sass/_colors.scss";
@import "@/assets/sass/_default.scss";
@import "@/assets/sass/_global.scss";

body {
  background: url("@/assets/images/light.png");
  background-size: cover;
}

#app {
  overflow-x: hidden;
}
</style>
