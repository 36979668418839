<template>
  <div class="navigation">
    <div class="logo">
      <router-link v-motion-slide-left :delay="200" to="/"
        >Ľuboš Kukla</router-link
      >
    </div>
    <nav>
      <router-link
        v-motion-slide-top
        :delay="400"
        class="underline"
        to="/projects"
        >Projekty</router-link
      >
      <a v-motion-slide-right :delay="600" @click="ShowContact">Kontakt</a>
    </nav>
    <ContactView v-if="this.$store.state.seeContact == true" />
  </div>
</template>

<script>
import ContactView from "@/components/Pages/Contact/ContactView.vue";

export default {
  methods: {
    ShowContact() {
      if (this.$store.state.seeContact == true) {
        this.$store.state.seeContact = false;
      } else {
        this.$store.state.seeContact = true;
      }
    },
  },
  components: {
    ContactView,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";
.navigation {
  width: 100%;
  max-width: 20rem;
  margin: 3% auto; /* bez auto na PC*/

  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 45%;
  padding: 1% 3%;
  border-right: 0.0625rem solid rgba(180, 188, 208, 0.33); /* 1px */
}
.logo a {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-weight: 600;
  text-align: center;

  font-size: 1.25rem; /* 20px */
}
.underline.router-link-active.router-link-exact-active {
  color: $txt-clr;
  font-weight: 900;
  font-size: 1.2rem;
  text-decoration: underline;
  text-underline-offset: 0.1rem;
}

nav {
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 10%;
  align-items: center;
  justify-content: center;

  a {
    color: rgba($txt-clr, 0.6);
    font-size: 0.875rem /* 14px */;
    transition-duration: 0.1s;
    cursor: pointer;

    &:hover {
      color: $txt-clr;
      transform: scale(1.05);
      text-decoration: underline;
      text-underline-offset: 0.1rem;
    }
  }
}

@media screen and (min-width: 400px) {
  .navigation {
    margin: 3%;
  }
}
@media screen and (min-width: 800px) {
  .navigation {
    max-width: 33rem;
  }

  .logo a {
    font-size: 2.25rem;
  }

  nav a {
    font-size: 1rem;
  }
}
</style>
