<template>
  <div class="footer">
    <div v-motion-slide-visible-once-top :delay="400" class="navigation">
      <div class="logo">
        <router-link to="/">Ľuboš Kukla</router-link>
      </div>
      <nav>
        <router-link to="/">Domov</router-link>
        <router-link to="/projects">Projekty</router-link>
        <a @click="GoContact()">Kontakt</a>
      </nav>
      <div class="links">
        <a
          href="https://www.instagram.com/luboskukla_webs/?hl=sk"
          target="_blank"
          ><img
            class="instagram"
            src="@/assets/icons/instagram.svg"
            alt="instagram"
        /></a>
        <a target="_blank" href="https://www.facebook.com/KuklaLubos/"
          ><img src="@/assets/icons/facebook.svg" alt="facebook"
        /></a>
        <a href="messenger.com" target="_blank"
          ><img src="@/assets/icons/messenger.svg" alt="messenger"
        /></a>
      </div>
    </div>
    <div v-motion-slide-visible-once-top :delay="400" class="copyright">
      <p>všetky práva vyhradené ©2023</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    GoContact() {
      window.scrollTo({ top: 0, behavior: "smooth" });

      setTimeout(() => {
        if (window.scrollY < 400) {
          this.$store.state.seeContact = true;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

.footer {
  border-radius: 1.25rem;
  backdrop-filter: blur(100px);
  background: linear-gradient(to bottom, #222453 0%, #1f193b 100%);
  background-color: #1f1838;
  border: 0.0625rem solid rgba(170, 170, 170, 0.301);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.logo a {
  color: $txt-clr;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
}

nav a,
.copyright p {
  color: $scd-clr;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
}

.links img {
  width: 2rem;
  height: 2rem;
}

.links .instagram {
  width: 1.85rem;
  height: 1.85rem;
}

.logo,
nav,
.links,
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
  padding: 1rem 0;
}
.links a img:hover {
  transform: scale(1.1);
  transition-duration: 0.1s;
}

.links {
  gap: 5%;
}

.logo {
  padding-top: 2rem;
}

.copyright {
  padding-bottom: 2rem;
}

.router-link-active,
.router-link-exact-active {
  color: $txt-clr;
  font-weight: 900;
}

@media screen and (min-width: 800px) {
  .logo {
    padding: 0;
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    padding: 1rem 5%;
    align-items: center;
    box-sizing: border-box;
  }

  nav {
    gap: 40%;
  }
  .links {
    gap: 20%;
  }

  .copyright {
    padding: 0.1rem 0 2.5rem;
  }

  nav a,
  .copyright p {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1300px) {
  .footer {
    width: 90%;
    margin: auto;
  }
}
</style>
